import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable, of} from 'rxjs';
import {Phobia} from './phobia';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhobiaService {

  constructor(
    private http: HttpClient
  ) { }

  private phobiaUrl = `${environment.apiUrl}/phobia`;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** GET phobia */
  getPhobia(): Observable<Phobia> {
    return this.http.get<Phobia>(this.phobiaUrl, this.httpOptions).pipe(
      catchError(this.handleError<Phobia>(`getPhobia`))
    );
  }
}
