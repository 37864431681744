import {Component, OnInit} from '@angular/core';
import {PhobiaService} from '../phobia.service';
import {Phobia} from '../phobia';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
  selector: 'app-phobia',
  templateUrl: './phobia.component.html',
  styleUrls: ['./phobia.component.css']
})
export class PhobiaComponent implements OnInit {
  phobia: Phobia;

  constructor(
    private phobiaService: PhobiaService,
    private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit() {
    this.getPhobia();
  }

  getPhobia(): void {
      this.ngxLoader.start();
      this.phobiaService.getPhobia().subscribe(result => {
      this.phobia = result;
      this.ngxLoader.stop();
    });
  }
}
